var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-navigation-drawer',{attrs:{"app":"","temporary":""},model:{value:(_vm.drawer),callback:function ($$v) {_vm.drawer=$$v},expression:"drawer"}},[_c('v-list',{attrs:{"dense":""}},[_c('v-subheader',[_vm._v("REPORTS")]),_c('v-list-item-group',{attrs:{"color":"primary"},model:{value:(_vm.selectedItem),callback:function ($$v) {_vm.selectedItem=$$v},expression:"selectedItem"}},_vm._l((_vm.menu_links),function(item,i){return _c('v-list-item',{key:i,attrs:{"to":item.url}},[_c('v-list-item-icon',[_c('v-icon',{attrs:{"dark":""}},[_vm._v(_vm._s(item.icon))])],1),_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(item.title)}})],1)],1)}),1)],1)],1),_c('v-app-bar',{attrs:{"dark":"","color":"primary","elevation":"0","app":"","absolute":"","height":"70px"}},[(!_vm.user)?_c('div',[_c('v-app-bar-nav-icon',{staticClass:"hidden-md-and-up",on:{"click":function($event){_vm.drawer = !_vm.drawer}}}),_c('v-btn',{attrs:{"elevation":"0","color":"secondary"},on:{"click":function($event){return _vm.goto('/register')}}},[_c('span',{staticClass:"text-h6"},[_vm._v("להרשם")])]),_c('v-btn',{attrs:{"text":"","dark":""},on:{"click":function($event){return _vm.goto('/login')}}},[_c('span',{staticClass:"text-h6"},[_vm._v("להתחבר")])])],1):_vm._e(),(_vm.user)?[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","text":""},on:{"click":_vm.logOut}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-logout")])],1)]}}],null,false,1202474347)},[_c('span',[_vm._v("יציאה")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","text":""},on:{"click":function($event){if (_vm.path.indexOf('/account') == -1)
                { _vm.$router.push({ name: 'MyLuggage2' }); }}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-account")])],1)]}}],null,false,2147101862)},[_c('span',[_vm._v("הגדרות")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","text":""},on:{"click":_vm.messages}},'v-btn',attrs,false),on),[_c('v-badge',{attrs:{"overlap":"","bordered":"","color":"green","content":_vm.numOfUnread,"value":_vm.numOfUnread,"left":""}},[_c('v-icon',[_vm._v("mdi-message-text")])],1)],1)]}}],null,false,1505285575)},[_c('span',[_vm._v("הודעות")])])]:_vm._e(),_c('v-spacer'),_c('v-spacer'),(!_vm.loading && _vm.user)?_c('addRecord'):_vm._e(),(!_vm.loading && !_vm.user)?_c('v-btn',{staticClass:"mx-2",attrs:{"color":"primary","elevation":"0"},on:{"click":function($event){return _vm.goto('/login')}}},[_vm._v(" פרסם מודעה "),_c('v-icon',{attrs:{"right":""}},[_vm._v("mdi-plus")])],1):_vm._e(),_c('v-btn',{attrs:{"color":"primary","elevation":"0"},on:{"click":function($event){return _vm.goto('/search')}}},[_vm._v(" חפש מודעה "),_c('v-icon',{attrs:{"right":""}},[_vm._v("mdi-magnify")])],1),_c('v-toolbar-title',[_c('v-img',{staticStyle:{"cursor":"pointer"},attrs:{"max-width":"350px","src":require("@/assets/logo_go.png"),"contain":"","max-height":"40"},on:{"click":function($event){return _vm.goto('/')}}})],1)],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }